<section class="subheader ">
    <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="path">
    <img onerror="this.src='./assets/img/default.png'" class=" img-title w-100 mob" [src]="pathMob">
    <div class="texto-s container">
        <h1 class="azul">{{title}}</h1>
        <div class='container-carousel-header w-100'>
            <div class="carousel-cell-header " *ngFor="let item of  itemCarrouselHeader;">
                <div class='content-carousel-header'>
                    <a [href]="url(item)">{{item.title}}</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="agsec1" >
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <app-carrousel-img *ngIf='datos' [data]='datos.gallery' [title]='datos.title'></app-carrousel-img>
            </div>
            <div class="col-md-4" *ngIf='itemsInfo.length > 0'>
                <ul class="list-group">
                    <li class="list-group-item active ">Más información
                    </li>
                    <li *ngFor='let item of itemsInfo' class="list-group-item "><a [href]="url(item)">{{item.title}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>