<section class="subheader " *ngIf='path'>
    <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="path">
    <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 mob" [src]="pathMob">
    <div class="texto-s container">
        <h1 class="blanco bold">{{title}}</h1>
        <div class='container-carousel-header w-100'>

            <div class="carousel-cell-header " *ngFor="let item of  itemCarrouselHeader;">
                <div class='content-carousel-header'>
                    <a [href]="url(item)">{{item.title}}</a>
                </div>
            </div>
        </div>
    </div>
  
    
</section>
<div class="row">

<ng-container #componenteDinamico1></ng-container>
<ng-container #componenteDinamico2></ng-container>
<ng-container #componenteDinamico3></ng-container>
<ng-container #componenteDinamico4></ng-container>
<ng-container #componenteDinamico5></ng-container>
<ng-container #componenteDinamico6></ng-container>
<ng-container #componenteDinamico7></ng-container>
<ng-container #componenteDinamico8></ng-container>
<ng-container #componenteDinamico9></ng-container>
<ng-container #componenteDinamico10></ng-container>
<ng-container #componenteDinamico11></ng-container>
<ng-container #componenteDinamico12></ng-container>
<ng-container #componenteDinamico13></ng-container>
<ng-container #componenteDinamico14></ng-container>
<ng-container #componenteDinamico15></ng-container>
<ng-container #componenteDinamico16></ng-container>
<ng-container #componenteDinamico17></ng-container>
<ng-container #componenteDinamico18></ng-container>
<ng-container #componenteDinamico19></ng-container>
<ng-container #componenteDinamico20></ng-container>

</div>
