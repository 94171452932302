
<section class="subheader " *ngIf='path'>
    <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 desk" [src]="path">
    <img onerror="this.src='./assets/img/default.png'" class="img-title w-100 mob" [src]="pathMob">
    <div class="texto-s container">
        <h1 class="blanco bold">{{title}}</h1>
        <div class='container-carousel-header w-100'>

            <div class="carousel-cell-header " *ngFor="let item of  itemCarrouselHeader;">
                <div class='content-carousel-header'>
                    <a [href]="url(item)">{{item.title}}</a>
                </div>
            </div>
        </div>
    </div>
</section>